.navBtn {
    font-size: 1.25rem;
    color: #fff;
    padding: 1rem;
}

.highlightBox {
    position: absolute;
    width: 100%;
    right: 0;
    opacity: 0;
    transition: opacity .3s;

    &.top {
        height: 80px;
        top: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.5) 35.94%, rgba(0, 0, 0, 0.3) 70.83%, rgba(0, 0, 0, 0) 100%);
    }

    &.btm {
        height: 140px;
        bottom: 0;
        background: linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, #000000d1 100%);
    }
}

.controls {
    transform: translateY(100%);
    transition: transform .3s;
}

.player {
    border: 0;
    overflow: hidden;

    video {
        width: 100%;
        height: 100%;
        background: #000;
    }

    &[data-controls] {
        .highlightBox {
            opacity: 1;
        }

        .controls {
            transform: translateY(0);
        }
    }
}

.sliderThumb {
    opacity: 1;
}

.btn {
    color: #fff;
    font-size: 1rem;
    padding-bottom: 1rem;

    @screen md {
        padding-bottom: 0;
    }

    &.active {
        color: theme("colors.primary.100");
    }
}

.tooltipC1 {
    .TBox {
        display: none;
    }

    &.visible:hover {
        .TBox {
            display: block;
        }
    }
}

.centerControls {
    $btnSize: 4rem;
    $boxWidth: calc(($btnSize * 3) + 3rem);
    height: $btnSize;
    width: $boxWidth;
    left: calc((100% - $boxWidth) / 2);
    top: calc((100% - $btnSize) / 2);

    .innerBtn {
        width: $btnSize;
        height: 100%;
        border-radius: 100%;
        background: rgba(0, 0, 0, 0.3);
        color: #fff;
        font-size: 1.75rem;
        transition: background .3s;

        &:hover {
            background: rgba(0, 0, 0, 0.4);
        }
    }
}